<template>
  <div class="page page--transactions">
    <v-row>
      <v-col cols="12" sm="4" md="6">
        <h1 class="page__title">
          {{ $t('nav_transactions') }}
        </h1>
      </v-col>
    </v-row>

    <transactions-overview
      :info="info"
      @change="loadData"
      @export="exportData"
    />

    <transactions-receipt-download :data="periodReceipts" />

    <transactions-data-table
      :key="nextPageKey"
      :data="info ? info.tableContent : []"
      :period="period"
      @export="exportData"
    />
  </div>
</template>

<script>
import moment from 'moment'

import auth from '@/auth'
import helpers from '@/helpers'
import transactionHelpers from '@/helpers/transaction'

import TransactionsOverview from '@/components/Transactions/Overview'
import TransactionsDataTable from '@/components/Transactions/DataTable'
import TransactionsReceiptDownload from '@/components/Transactions/ReceiptDownload'

export default {
  name: 'TransactionsView',
  components: {
    TransactionsDataTable,
    TransactionsOverview,
    TransactionsReceiptDownload
  },
  data() {
    return {
      auth: auth,
      info: null,
      allGroupReceipts: null,
      periodReceipts: [],
      period: moment().format('YYYY-MM'),
      nextPageKey: null
    }
  },
  watch: {
    '$store.state.selectedGroup'() {
      // reset group receipt data on selected group changed
      this.allGroupReceipts = null
      if (this.$store.state.selectedGroup) {
        this.loadData(this.period)
      }
    }
  },
  methods: {
    async loadData(period) {
      let selectedGroupAccountId = this.$store.getters.selectedGroupAccountId
      // no info on selected group, that means basic data is not loaded
      if (!selectedGroupAccountId) {
        await helpers.fetchDashboardData()
        selectedGroupAccountId = this.$store.getters.selectedGroupAccountId
        // this return statement prevents first load without data
        // @todo: check if this has problem later
        return
      }

      this.period = period
      this.info = await transactionHelpers.fetchTransactionSummary(
        selectedGroupAccountId,
        period
      )
      const parkings = await transactionHelpers.fetchParkingTransactions(
        selectedGroupAccountId,
        period
      )
      const nonParkings = await transactionHelpers.fetchNonParkingTransactions(
        selectedGroupAccountId,
        period
      )

      this.info.tableContent = [...nonParkings, ...parkings.list]
      // also update the info for loading the next page
      this.nextPageKey = parkings.nextPageKey

      // update receipt download button data
      if (!this.allGroupReceipts) {
        this.allGroupReceipts = await transactionHelpers.fetchGroupReceipt(
          selectedGroupAccountId
        )
      }

      this.periodReceipts = this.allGroupReceipts.filter((receipt) => {
        return (
          this.visibleBillStatuses().indexOf(
            receipt.bill_status_name.toLowerCase()
          ) !== -1 &&
          receipt.amount > 0 &&
          moment(receipt.begin_period).format('YYYY-MM') === period
        )
      })

      this.$forceUpdate()
    },
    visibleBillStatuses() {
      return [
        'completed',
        'fee_added',
        'fee_warned',
        'debt_collecting',
        'uncollectible',
        'processed'
      ]
    },
    /**
     * Handle when user scroll to the bottom of the page
     */
    async loadMoreData() {
      if (!this.nextPageKey) {
        return
      }

      const parkings = await transactionHelpers.fetchParkingTransactions(
        this.$store.getters.selectedGroupAccountId,
        this.period,
        this.nextPageKey
      )

      this.info.tableContent.push(...parkings.list)

      this.info.tableContent.push('dummy')
      this.info.tableContent.splice(-1)

      this.nextPageKey = parkings.nextPageKey
    },
    /**
     * Call backend to export the displayed transactions
     * @param {Object} args contain list_ids (for parking transactions) and / or list_types (for non parking transactions)
     * If none of them present, download all transaction in the selected period
     */
    async exportData(args) {
      await transactionHelpers.download({
        account_id: this.$store.getters.selectedGroupAccountId,
        start_date: moment(this.period).format(moment.defaultFormatUtc),
        ...args
      })
    }
  },
  async created() {
    // fetch transaction data
    await this.loadData(this.period)

    // detect when user scroll to the bottom
    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.loadMoreData()
      }
    }
  },
  mounted() {
    helpers.sendTrackingEvent('MAIN PAGE', 'show_business_account_transactions')
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.page--transactions {
  // padding-top: 24px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding-top: 12px;
  }
}
</style>

<style lang="scss">
@import '@/style/common';

.v-chip__close.sortable {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: none;
  }
}

thead.v-data-table-header th {
  min-width: 94px;
}

/* @todo: remove when overview component is reused */
.page__title {
  margin-bottom: 10px;
}

.transaction__actions {
  position: relative;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.transaction__period-selector {
  display: inline-block;
  vertical-align: top;
  margin-left: 16px;

  .period-selector__trigger {
    padding: 11px 22px !important;
  }

  .v-picker {
    position: absolute;
    right: 12px;
    top: 100%;
    z-index: 8;

    &__title {
      display: none;
    }
  }
}
</style>
