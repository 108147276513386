import moment from 'moment'
import getSymbolFromCurrency from 'currency-symbol-map'
import fileDownload from 'js-file-download'

import api from '@/helpers/api'
import i18n from '@/plugins/i18n'
import store from '@/store'
import helpers from '.'

export default {
  displayDateTimeFormat: 'YYYY-MM-DD HH:mm',
  /**
   * Fetch all parking data of the selected group, in the selected period
   * @param {Integer} groupAccountId
   * @param {String} period
   * @param {Integer} page page to load data. If not specified, load the first page
   */
  async fetchParkingTransactions(groupAccountId, period, nextPageKey) {
    const defaultResult = {
      list: [],
      nextPageKey: null
    }

    if (!groupAccountId || !period) {
      return defaultResult
    }

    // get start and end date from period data
    const periodStart = moment(period).utc().format(moment.defaultFormatUtc)
    const periodEnd = moment(period)
      .endOf('month')
      .utc()
      .format(moment.defaultFormatUtc)

    const apiSettings = {
      method: 'get',
      service: 'product',
      url: 'transactions%2F',
      params: {
        account_id: groupAccountId,
        type: 'parking',
        start_date: periodStart,
        end_date: periodEnd,
        next_cursor: nextPageKey || '',
        page_size: 50 // optional - default = 50
      }
    }

    try {
      const response = await api.promise(apiSettings)
      if (!response || !response.product_items) {
        return defaultResult
      }

      const transactions = response.product_items.collection
      const parkings = []

      transactions.forEach((item) => {
        const transaction = {
          id: item.id,
          name: `${item.user.first_name || ''} ${item.user.last_name || ''}`,
          cost_center: item.cost_center || '-',
          car_num: item.parking.car_registration_number,
          // display the start and end date in local time
          parking_time: this.getParkingTime(item),
          no_vat_price: (
            item.pricing.item_amount + item.pricing.service_amount
          ).toFixed(2),
          vat_price: (
            item.pricing.item_vat_amount + item.pricing.service_vat_amount
          ).toFixed(2),
          total_price: item.pricing.total_amount.toFixed(2),
          currency: getSymbolFromCurrency(item.pricing.currency),
          report_id: item.id
        }
        parkings.push(transaction)
      })

      return {
        list: parkings,
        nextPageKey: response.product_items.lastEvaluatedKey
      }
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return defaultResult
    }
  },
  async fetchNonParkingTransactions(groupAccountId, period) {
    if (!groupAccountId || !period) {
      return []
    }

    // get start and end date from period data
    const periodStart = moment(period).utc().format(moment.defaultFormatUtc)
    const periodEnd = moment(period)
      .endOf('month')
      .utc()
      .format(moment.defaultFormatUtc)

    const apiSettings = {
      method: 'get',
      service: 'product',
      url: 'transactions/group',
      params: {
        account_id: groupAccountId,
        start_date: periodStart,
        end_date: periodEnd
      }
    }

    try {
      const response = await api.promise(apiSettings)
      if (!response || !response.group_product_items) {
        return []
      }

      const transactions = response.group_product_items
        // does not include parkings in non-parking transaction list
        .filter((item) => item.name.toLowerCase() !== 'parking')

      const result = transactions.map((transaction) => {
        return {
          name: `${i18n.t('transaction_' + transaction.name.toLowerCase())} (x${transaction.total})`,
          no_vat_price: transaction.group_total_amount_no_vat
            ? transaction.group_total_amount_no_vat.toFixed(2)
            : '-',
          vat_price: transaction.group_total_vat
            ? transaction.group_total_vat.toFixed(2)
            : '-',
          total_price: transaction.group_total_amount_incl_vat
            ? transaction.group_total_amount_incl_vat.toFixed(2)
            : '-',
          currency: getSymbolFromCurrency(transaction.currency),
          start_date: '-',
          end_date: '-',
          car_num: '-',
          cost_center: '-',
          report_id: transaction.name
        }
      })

      return result
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return []
    }
  },
  /**
   * Fetch the summary for selected group, in the selected period
   * @param {Integer} groupAccountId
   * @param {String} period
   */
  async fetchTransactionSummary(groupAccountId, period) {
    if (!groupAccountId || !period) {
      return null
    }

    // get start and end date from period data
    const periodStart = moment(period)
      .startOf('month')
      .utc()
      .format(moment.defaultFormatUtc)
    const periodEnd = moment(period)
      .endOf('month')
      .utc()
      .format(moment.defaultFormatUtc)

    const apiSettings = {
      method: 'get',
      service: 'product',
      url: 'transactions/summary',
      params: {
        account_id: groupAccountId,
        start_date: periodStart,
        end_date: periodEnd
      }
    }

    try {
      const response = await api.promise(apiSettings)

      let totalCost = response.summary.total_amount
        .filter((item) => item.total_amount > 0)
        .map((item) => {
          // return `${item.total_amount.toFixed(2)} ${getSymbolFromCurrency(item.currency)}`
          return helpers.displayMoney(
            item.total_amount,
            getSymbolFromCurrency(item.currency),
            2
          )
        })
        .join(' + ')

      if (totalCost === '') {
        totalCost = 0
      }

      const totalParkings = response.summary.total_transaction_type.find(
        (item) => item.product_item_type_name.toLowerCase() === 'parking'
      )

      const result = {
        totalCost: totalCost,
        parkings: totalParkings ? totalParkings.total : 0
      }
      return result
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },
  /**
   * Download selected transaction
   */
  async download(args) {
    // if no end date specified, take the default as the start date's end of the month
    if (!args.end_date) {
      args.end_date = moment(args.start_date)
        .endOf('month')
        .utc()
        .format(moment.defaultFormatUtc)
    }
    // convert start date to utc before sending to backend
    args.start_date = moment(args.start_date)
      .startOf('month')
      .utc()
      .format(moment.defaultFormatUtc)

    const apiSettings = {
      method: 'get',
      service: 'product',
      url: 'transactions/export',
      params: args
    }

    try {
      const response = await api.promise(apiSettings)
      const period = moment(args.start_date).format('YYYY-MM')
      fileDownload(response, `transactions_${period}.csv`)
    } catch (error) {
      store.dispatch('setSystemMessage', i18n.t('transactions_export_failed'))
    }
  },

  async fetchGroupReceipt(groupAccountId) {
    if (!groupAccountId) {
      return []
    }

    const apiSettings = {
      method: 'get',
      service: 'billing',
      url: `bills/accounts/${groupAccountId}`
    }

    try {
      const response = await api.promise(apiSettings)
      return response.bills
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return []
    }
  },

  /**
   * Return the parking time of a transaction in
   * YYYY-MM-DD HH:mm:ss
   * YYYY-MM-DD HH:mm:ss
   * format (2 lines, first is start time, second is end time)
   */
  getParkingTime(transaction) {
    const startTime = moment(transaction.parking.start_time).isValid()
      ? moment
          .utc(transaction.parking.start_time)
          .local()
          .format(this.displayDateTimeFormat)
      : '-'
    const endTime = moment(transaction.parking.end_time).isValid()
      ? moment
          .utc(transaction.parking.end_time)
          .local()
          .format(this.displayDateTimeFormat)
      : '-'
    return `${startTime}<br>${endTime}`
  },

  /**
   * Append current locale as a parameter to an url
   * @returns {String} url + &lang=..
   */
  getUrlWithLocale(url) {
    const lang = helpers.fetchStorage('lang') || 'en'
    return url + '&lang=' + lang
  }
}
