<template>
  <v-row>
    <v-col cols="12">
      <ul class="receipt-list">
        <li
          class="receipt-list__item pkmn-link-button"
          v-for="receipt in data"
          :key="receipt.id"
          @click="download(receipt.pdf_link)"
        >
          {{ $t('view_pdf_receipt') }} ({{ displayReceiptAmount(receipt) }})
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
import getSymbolFromCurrency from 'currency-symbol-map'
import helpers from '@/helpers'
import transactionHelpers from '@/helpers/transaction'

export default {
  name: 'TransactionReceiptDownload',
  props: ['data'],
  methods: {
    download(url) {
      window.open(transactionHelpers.getUrlWithLocale(url))
    },
    displayReceiptAmount(receipt) {
      return helpers.displayMoney(
        receipt.amount,
        getSymbolFromCurrency(receipt.currency)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.receipt-list {
  list-style: none;
}

.receipt-list__item {
  margin: 4px;
  cursor: pointer;
}
</style>
