<template>
  <span>{{ animatedValue }}</span>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'Animated-Num',
  props: {
    value: {
      type: Number,
      required: true
    },
    decimalPlaces: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tweenTime: 0.5,
      tweenedNumber: 0
    }
  },
  computed: {
    animatedValue() {
      return this.tweenedNumber.toFixed(this.decimalPlaces)
    }
  },
  watch: {
    value(newValue, oldValue) {
      gsap.to(this.$data, this.tweenTime, { tweenedNumber: newValue })
    }
  }
}
</script>
