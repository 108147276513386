<template>
  <div class="group-selector">
    <v-btn
      v-if="!$store.state.selectedGroup"
      class="group-selector__button group-selector__button--placeholder pkmn-button pkmn-button--full-width-mobile"
      color="primary"
      large
      outlined
    >
      <v-progress-circular color="#37bd00" indeterminate size="16" />
    </v-btn>
    <v-select
      v-else
      class="group-selector__button"
      :items="options"
      v-model="$store.state.selectedGroup"
      outlined
      :loading="!options || options.length === 0"
      color="primary"
      :menu-props="{ maxHeight: 276 }"
      @input="selectGroup"
    >
    </v-select>
  </div>
</template>

<script>
import companyHelpers from '@/helpers/company'

export default {
  name: 'GroupSelector',
  data() {
    return {
      options: []
    }
  },
  watch: {
    '$store.state.groupData': {
      deep: true,
      handler() {
        this.buildGroupOptions()
      }
    }
  },
  methods: {
    buildGroupOptions() {
      this.options = companyHelpers.buildGroupOptions()
    },
    selectGroup(group) {
      this.$emit('input', group)
    }
  },
  mounted() {
    this.buildGroupOptions()
  }
}
</script>

<style lang="scss">
@import '@/style/common';

.group-selector {
  display: inline-flex;
  min-width: 160px;
  vertical-align: bottom;

  .v-text-field__details {
    display: none;
  }

  .v-text-field--filled > .v-input__control > .v-input__slot,
  .v-text-field--full-width > .v-input__control > .v-input__slot,
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 44px;
    margin-bottom: 0;
  }

  fieldset {
    border: 2px solid $color-brand;
  }

  .theme--light.v-select .v-select__selection--comma {
    color: $color-brand;
    font-weight: 600;
    margin: 0;

    & + input {
      display: none;
    }
  }

  .v-select__selections {
    justify-content: center;
  }

  .v-select__selection:after {
    font-family: 'Material Design Icons';
    content: '\F035D';
    margin-left: 8px;
    font-size: 18px;
    vertical-align: bottom;
  }

  .v-input__append-inner {
    display: none;
  }
}

.group-selector__button--placeholder {
  width: 100%;
}
</style>
